<template lang="pug">
    .unsubscribe-from-all
        template(v-if="!subscriber.deny_all_subscriptions")
            div
                .description Вы можете запретить отправку сообщений некоторым отправителям, или сразу всем.
                el-button(@click="toggleSubscriberBlacklisted" icon="el-icon-close-notification" type="primary") Отписаться от всех
        template(v-else)
            div
                .description <b class="alert">Внимание!</b> Вы отписались от всех возможных отправителей
                el-button(@click="toggleSubscriberBlacklisted" icon="el-icon-bell" type="warning") Отменить отписку от всех

</template>

<script>
    import { mapActions, mapGetters } from 'vuex'
    import { SAVE_SUBSCRIBER } from '@/store/types'

    export default {
        computed: {
            ...mapGetters([
                'subscriber',
            ]),
        },
        methods: {
            ...mapActions([
                SAVE_SUBSCRIBER,
            ]),
            toggleSubscriberBlacklisted(){
                this.subscriber.deny_all_subscriptions = !this.subscriber.deny_all_subscriptions

                return this.SAVE_SUBSCRIBER(this.subscriber).then(()=>{
                    this.$emit('saved')
                }).catch((error)=>{
                    this.$root.pushAppMessages(error.response.data)

                    return Promise.reject(error)
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .unsubscribe-from-all{
        .alert{
            color: $color--warning;
        }
        .description{
            padding-right: $padding;
        }

        > div{
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: $max-width--tablets){
                display: block;

                .description{
                    padding: 0 0 $padding12;
                }
            }
        }
    }
</style>
