<i18n src="@/i18n/http.json"></i18n>

<template lang="pug">
    .senders.view
        section.supercompact.deny-all(v-if="false")
            .section-content-wrapper
                UnsubscribeFromAll(@saved="getSubscriberSubscriptions")
        section.compact.senders-list(v-if="!subscriber.deny_all_subscriptions")
            .section-content-wrapper
                h2 Подписки
                .section-content-wrapper
                    SendersFilterForm(v-bind:senders_filter_form="senders_filter_form" @created="sendersFilterCreated" @updated="sendersFilterUpdated" @reset="resetSendersFilter")
                    template(v-if="subscriptions.total")
                        .table
                            .th
                                .td
                                    i(v-if="senders_filter_form.sorting.property === 'sender_name'" :class="senders_filter_form.sorting.direction === 'asc' ? 'el-icon-bottom' : 'el-icon-top'")
                                    a(@click.prevent="sortSubscriptions('sender_name')") Отправитель
                                .td Канал
                                .td
                                    i(v-if="senders_filter_form.sorting.property === 'unsubscribed'" :class="senders_filter_form.sorting.direction === 'asc' ? 'el-icon-bottom' : 'el-icon-top'")
                                    a(@click.prevent="sortSubscriptions('unsubscribed')") Статус
                            .tr.striped(v-for="subscription in subscriptions.data" :key="subscription.id")
                                .td(v-if="auth_user.loggedIn()")
                                    b {{ subscription.phone }}
                                .td.name.tablets--inline
                                    b {{ subscription.sender_name  }}
                                .td.channel.tablets--inline SMS
                                .td(@click.stop="saveSubscriberSubscription(subscription)")
                                    el-switch(v-model="subscription.unsubscribed" :active-text="subscription.unsubscribed ? 'Отписан' : 'Подписан'" :class="subscription.unsubscribed ? 'active' : subscription.unsubscribed === false ? 'inactive' : ''")
                        .pagination.senders-list-pagination(v-if="subscriptions.to < subscriptions.total")
                            el-button(@click="loadMoreSubscriptions" type="primary") Показать ещё
                    template(v-else-if="senders.total")
                        .table
                            .th
                                .td
                                    i(v-if="senders_filter_form.sorting.property === 'name'" :class="senders_filter_form.sorting.direction === 'asc' ? 'el-icon-bottom' : 'el-icon-top'")
                                    a(@click.prevent="sortSenders('name')") Отправитель
                                .td Канал
                                .td Статус
                            .tr.striped(v-for="sender in senders.data" :key="sender.id")
                                .td.name.tablets--inline
                                    b {{ sender.name }}
                                .td.channel.tablets--inline SMS
                                .td(@click.stop="saveSubscriberSubscription({ ...sender, ...{sender_name: sender.name }})")
                                    el-switch(v-model="sender.unsubscribed" :disabled="!subscriber.hasCompany() && !subscriber.terms_accepted_at" :active-text="sender.unsubscribed === false ? 'Подписан' : sender.unsubscribed === true ? 'Отписан' : 'Отпишитесь'" :class="sender.unsubscribed ? 'active' : sender.unsubscribed === false ? 'inactive' : ''")
                            .pagination.senders-list-pagination(v-if="senders.to < senders.total")
                                el-button(@click="loadMoreSendersSuggestions" type="primary") Показать ещё
                    template(v-else)
                        h3 Укажите отправителя и отпишитесь:
                        SubscriptionEditForm(:sender_name.sync="senders_filter_form.name" @saved="subcriptionSaved")
        section.compact.subscription-create(v-if="subscriptions.total || senders.total")
            .section-content-wrapper <strong><a @click="$router.push({name: 'subscription.create'})">Отпишитесь от произвольного отправителя</a></strong>, если не нашли в списке.
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {
    GET_SENDERS_SUGGESTIONS,
    GET_SUBSCRIBER_SUBSCRIPTIONS,
    GET_SUBSCRIBER_SUBSCRIPTION,
    SAVE_SUBSCRIBER_SUBSCRIPTION,
    SET_SUBSCRIBER, RESET_STORE
} from '@/store/types'

import SubscriptionEditForm from "@/components/Subscription/SubscriptionEditForm"
import UnsubscribeFromAll from "@/components/Subscription/UnsubscribeFromAll"
import SendersFilterForm from "@/components/Senders/SendersFilterForm"
import SubscriptionsFilterForm from "@/components/Subscriptions/SubscriptionsFilterForm"

const senders_default_filter_form = () => {
    return {
        default: true,
        sorting: {
            property: 'created_at',
            direction: 'desc',
        },
        channel: {alias: 'sms'},
        tags: [],
        name: '',
    }
}
const default_subscriptions = () => {
    return {
        current_page: 1,
        total: 0,
    }
}
const default_senders = () => {
    return {
        current_page: 1,
        total: 0,
    }
}

export default {
    metaInfo() {
        return {
            title: this.title
        }
    },
    components: {
        SendersFilterForm,
        SubscriptionEditForm,
        UnsubscribeFromAll,
        SubscriptionsFilterForm,
    },
    data() {
        return {
            senders_filter_visible: true,
            default_senders_filter: true,
            senders_filter_form: senders_default_filter_form(),
            channels: [{
                alias: 'sms',
                name: 'SMS',
            }],
            sorting_directions: [
                'desc',
                'asc',
            ],
            subscriptions: default_subscriptions(),
            senders: default_senders(),
        }
    },
    computed: {
        ...mapGetters([
            'auth_user',
            'subscriber',
        ]),
        title() {
            return 'Подписки'
        },
    },
    methods: {
        ...mapActions([
            GET_SENDERS_SUGGESTIONS,
            GET_SUBSCRIBER_SUBSCRIPTIONS,
            GET_SUBSCRIBER_SUBSCRIPTION,
            SAVE_SUBSCRIBER_SUBSCRIPTION
        ]),
        ...mapMutations([
            SET_SUBSCRIBER,
        ]),
        resetSendersFilter() {
            this.senders_filter_form = senders_default_filter_form()
            this.default_senders_filter = true
        },
        resetSenders() {
            this.subscriptions = default_subscriptions()
            this.senders = default_senders()
        },
        // Subscriptions
        sendersFilterCreated() {
            this.getSubscriptions(true)
        },
        sendersFilterUpdated() {
            this.default_senders_filter = false
            this.getSubscriptions(true)
        },
        getSubscriptions(with_reset) {
            if (!this.subscriber.deny_all_subscriptions) {
                return this.GET_SUBSCRIBER_SUBSCRIPTIONS({
                    ...this.senders_filter_form, ...{
                        page: this.subscriptions.current_page
                    }
                }).then((subscriptions) => {
                    if (with_reset) this.resetSenders()

                    let list = (this.subscriptions.data) ? this.subscriptions.data : []

                    this.subscriptions = subscriptions

                    Object.assign(this.subscriptions.data, list.concat(this.subscriptions.data))

                    // No subscriptions found
                    if (!this.subscriptions.total) {
                        if (!this.default_senders_filter) {
                            this.getSendersSuggestions(with_reset)
                        } else {
                            this.$router.push({name: 'senders.suggestions'})
                        }
                    }
                }).catch((error) => {
                    if (error.response && error.response.data.errors) {
                        this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                            return this.$t(message)
                        }))
                    }

                    return Promise.reject(error)
                })
            }
        },
        loadMoreSubscriptions() {
            this.subscriptions.current_page++

            return this.getSubscriptions()
        },
        sortSubscriptions(property) {
            this.senders_filter_form.sorting.property = property
            this.sorting_directions.reverse()
            this.senders_filter_form.sorting.direction = this.sorting_directions[0]
        },
        // Suggestions
        getSendersSuggestions(with_reset) {
            if (with_reset) this.resetSenders()

            return this.GET_SENDERS_SUGGESTIONS({...this.senders_filter_form, ...{page: this.senders.current_page}}).then((senders) => {
                let list = (this.senders.data) ? this.senders.data : []

                senders.data = this.mergeSenderStatus(senders.data)

                this.senders = senders

                Object.assign(this.senders.data, list.concat(this.senders.data))
            }).catch((error) => {
                if (error.response && error.response.data.errors) {
                    this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                        return this.$t(message)
                    }))
                }

                return Promise.reject(error)
            })
        },
        loadMoreSendersSuggestions() {
            this.senders.current_page++

            return this.getSendersSuggestions()
        },
        mergeSenderStatus(senders) {
            return this._.map(senders, (sender) => {
                let subscription = this._.filter(this.subscriptions, (subscription) => {
                    return subscription.sender_name === sender.name
                })[0]

                if (subscription !== undefined) Object.assign(sender, {unsubscribed: subscription.unsubscribed})

                return sender
            })
        },
        getSubscriberSubscriptions() {
            return this.GET_SUBSCRIBER_SUBSCRIPTIONS({
                subscriber: this.subscriber,
                page: this.senders.current_page
            }).then((senders) => {
                this.senders = senders
            }).catch((error) => {
                this.$root.pushAppMessages(error.response.data)
                return Promise.reject(error)
            })
        },
        saveSubscriberSubscription(subscription) {
            this.SET_SUBSCRIBER(Object.assign(this.subscriber, {check_terms_accepted: true}));

            if (this.subscriber.hasCompany() || (this.subscriber.terms_accepted_at !== null)) {
                return this.SAVE_SUBSCRIBER_SUBSCRIPTION(subscription).then((subscription) => {
                    if (subscription.unsubscribed) {
                        if (window.gtag !== undefined) {
                            gtag('event', 'tap_unsubscribe', {
                                'event_category': 'onboarding'
                            })
                            console.log('GA goal reached: tap_unsubscribe')
                        }

                        if (window.ym !== undefined) {
                            window.ym(74625946, 'reachGoal', 'tap_unsubscribe')
                            console.log('YM goal reached: tap_unsubscribe')
                        }
                    }
                }).catch((error) => {
                    if (error.response.data.errors) {
                        this.$root.pushAppMessages(error.response.data.errors.map((message) => {
                            return this.$t(message)
                        }))
                    }
                });
            }
        },
        subcriptionSaved() {
            this.resetSendersFilter()

            if (window.gtag !== undefined) {
                gtag('event', 'tap_unsubscribe', {
                    'event_category': 'onboarding'
                })
                console.log('GA goal reached: tap_unsubscribe')
            }

            if (window.ym !== undefined) {
                window.ym(74625946, 'reachGoal', 'tap_unsubscribe')
                console.log('YM goal reached: tap_unsubscribe')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.senders {
    .deny-all {
        background: $color--secondary;
        color: $color--black;
    }

    &-list {
        background: $color--background;
        color: $color--black;

        @media (max-width: $max-width--tablets) {
            .tr {
                @include border($position: bottom);

                padding: $padding18 0;

                .td {
                    padding-top: 0;

                    &.tablets--inline {
                        display: inline-block;
                    }

                    &.channel {
                        float: right;

                    }
                }
            }
        }
    }

    .subscription {
        &-create {
            background: $color--secondary;
            color: $color--black;
        }

        &-edit {
            &-form {
                width: 50%;
            }

            @media (max-width: $max-width--tablets) {
                &-form {
                    width: 100%;
                }
            }
        }
    }
}
</style>
