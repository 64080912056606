<template lang="pug">
  .subscriptions-filter-form
    el-form(ref="subscription_form" label-position="top")
      .form-row(v-if="sender_tags.length")
        el-checkbox-group.senders-filter-tags(v-model="subscriptions_filter_form.tags")
          el-checkbox(v-for="tag in sender_tags" :label="tag.id" :key="tag.id") {{ tag.name }}
      .form-row
        el-form-item(v-if="false" prop="channel_alias" label="Канал")
          el-select(v-model="subscriptions_filter_form.channel.alias" placeholder="Канал")
            el-option(v-for="channel in channels" :label="channel.name" :value="channel.alias" :key="channel.id" )
        el-form-item.name(prop="name" label="")
          el-input(v-model="subscriptions_filter_form.name" placeholder="Поиск отправителя" suffix-icon="el-icon-search" clearable)
</template>

<script>
import {mapActions} from 'vuex'
import {
  GET_SENDER_TAGS,
} from '@/store/types'

const subscriptions_default_filter_form = () => {
  return {
    sorting: {
      property: 'created_at',
      direction: 'desc',
    },
    channel: {alias: 'sms'},
    tags: [],
    name: '',
  }
}

export default {
  props: [
    'subscriptions_filter_form'
  ],
  data() {
    return {
      sender_tags: [],
      channels: [{
        alias: 'sms',
        name: 'SMS',
      }],
    }
  },
  created() {
    this.getSenderTags().then(()=>{
      this.$emit('created')
    })

    // Watch filter form changes
    this.$watch('subscriptions_filter_form', () => {
      this.$emit('updated')
    }, {
      // Run automatically?
      immediate: false,
      // Watch all properties changes
      deep: true
    })
  },
  methods: {
    ...mapActions([
      GET_SENDER_TAGS,
    ]),
    getSenderTags(){
      return this.GET_SENDER_TAGS().then((sender_tags) => {
        this.sender_tags = sender_tags
      }).catch((error) => {
        if(error.response.data.errors){
          this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
            return this.$t(message)
          }))
        }

        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.subscriptions-filter-form {
  .name {
    width: 100%;

    .el-select {
      width: 120px;
    }
  }
}
</style>
