<template lang="pug">
    .subscription-edit-form
        el-form(:model="subscription_form" :rules="subscription_form_rules" @keydown.enter.native.prevent="saveSubscription" ref="subscription_form" label-position="top")
            .form-row
                el-form-item.sender-name(prop="sender_name" label="")
                    el-autocomplete(v-model="subscription_form.sender_name" :fetch-suggestions="suggestSender" value-key="name" @select="onSenderSelect" placeholder="" :trigger-on-focus="false" clearable)
                el-form-item
                    el-button(v-if="!subscriber.confirmation_uid" @click="saveSubscription", type="primary") Отписаться
                    el-button(v-else @click="confirmSubscription", type="primary") Подтвердить
            .form-row(v-if="auth_user.loggedIn()")
                el-form-item(prop="phone", label="Телефон")
                    el-input(v-model="subscription_form.phone" type="text")
                el-form-item(v-if="subscriber.confirmation_uid" prop="confirmation_code" label="Код подтверждения")
                    el-input(v-model="subscription_form.confirmation_code" type="text")
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import { GET_SENDERS, GET_SENDERS_QUIETLY, SAVE_SUBSCRIBER_SUBSCRIPTION } from '@/store/types'

    const default_subscription_form = ()=>{
        return {
            channel_alias: 'sms',
            sender_name: undefined,
        }
    }

    export default {
        props: {
            sender_name: String
        },
        data() {
            return {
                subscription_form: default_subscription_form(),
                subscription_form_rules: {
                    channel_alias: [
                        { required: true, message: 'Выберите канал', trigger: 'blur' },
                    ],
                    sender_name: [
                        { required: true, message: 'Укажите отправителя', trigger: 'blur' },
                        { max: 11, message: 'Длина имени отправителя не должна превышать 11 символов', trigger: 'blur' },
                        { pattern: /^[A-Za-z0-9!.!'&*?,":_\s-]+$/, message: 'Имя отправителя содержит недопустимые символы', trigger: 'blur' }
                    ],
                },
                channels: [{
                    alias: 'sms',
                    name: 'SMS',
                }],
            }
        },
        computed: {
            ...mapGetters([
                'auth_user',
                'subscriber',
            ]),
        },
        watch: {
            sender_name(){
                this.subscription_form.sender_name = this.sender_name
            }
        },
        created() {
            this.subscription_form.sender_name = this.sender_name
        },
        methods: {
            ...mapActions([
                GET_SENDERS,
                GET_SENDERS_QUIETLY,
                SAVE_SUBSCRIBER_SUBSCRIPTION,
            ]),
            suggestSender(queryString, cb) {
                this.GET_SENDERS_QUIETLY({ name: this.subscription_form.sender_name, limit: 5 }).then((senders)=>{
                    cb(senders)
                }).catch((error)=>{
                    this.$root.pushAppMessages(error.response.data)
                })
            },
            onSenderSelect(item) {},
            saveSubscription(){
                this.$refs.subscription_form.validate((valid) => {
                    if (valid) {
                        return this.SAVE_SUBSCRIBER_SUBSCRIPTION(this.subscription_form).then(()=>{
                            this.subscription_form = default_subscription_form()

                            this.$emit('saved')

                            this.$root.pushAppMessages('Отписка сохранена', 'success')
                        }).catch((error)=>{
                            this.$root.pushAppMessages(error.response.data)
                        })
                    } else return false
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    .subscription-edit-form{
        .form-row{
            align-items: flex-end;
        }
        .sender-name{
            width: 100%;
        }
    }
</style>
