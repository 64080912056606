import { render, staticRenderFns } from "./Subscriptions.vue?vue&type=template&id=0015e1b1&scoped=true&lang=pug&"
import script from "./Subscriptions.vue?vue&type=script&lang=js&"
export * from "./Subscriptions.vue?vue&type=script&lang=js&"
import style0 from "./Subscriptions.vue?vue&type=style&index=0&id=0015e1b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0015e1b1",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/http.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fopt-in.ru%2Fsrc%2Fviews%2Fsubscriptions%2FSubscriptions.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports