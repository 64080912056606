<template lang="pug">
.senders-filter
  a.control(v-if="!filter_on" @click="filter_on = !filter_on") <i class="el-icon-arrow-down" /> Показать фильтр и поиск
  a.control(v-else @click="filter_on = !filter_on") <i class="el-icon-arrow-up" /> Скрыть фильтр и поиск
  .senders-filter-wrapper(:class="filter_on ? 'on' : 'off'")
    el-form(ref="subscription_form" label-position="top")
      .form-row(v-if="sender_tags.length")
        el-checkbox-group.senders-filter-tags(v-model="senders_filter_form.tags")
          el-checkbox(v-for="tag in sender_tags" :label="tag.id" :key="tag.id") {{ tag.name }}
      .form-row
        el-form-item(prop="name" label="")
          el-input(v-model="senders_filter_form.name" placeholder="Поиск отправителя" suffix-icon="el-icon-search" clearable)
            el-select(v-if="false" v-model="senders_filter_form.channel.alias" slot="append" placeholder="Канал")
              el-option(v-for="channel in channels" :label="channel.name" :value="channel.alias" :key="channel.id" )
      a.reset(v-if="false" @click="reset" type="primary") Очистить фильтры
</template>

<script>
import {mapActions} from 'vuex'
import {
  GET_SENDER_TAGS,
} from '@/store/types'

const senders_default_filter_form = () => {
  return {
    sorting: {
      property: 'frequency',
      direction: 'desc',
    },
    channel: {alias: 'sms'},
    tags: [],
    name: '',
  }
}

export default {
  props: [
    'senders_filter_form'
  ],
  data() {
    return {
      filter_on: false,
      updated: false,
      sender_tags: [],
      channels: [{
        alias: 'sms',
        name: 'SMS',
      }],
    }
  },

  created() {
    // Watch filter form changes
    this.$watch('senders_filter_form', () => {
      this.updated = true
      // Emit an updated event
      this.$emit('updated')
    }, {
      // Run automatically?
      immediate: false,
      // Watch all properties changes
      deep: true
    })

    this.getSenderTags().then(() => {
      this.$emit('created')
    })
  },
  methods: {
    ...mapActions([
      GET_SENDER_TAGS,
    ]),
    reset() {
      this.updated = false

      this.$emit('reset')
    },
    getSenderTags() {
      return this.GET_SENDER_TAGS().then((sender_tags) => {
        this.sender_tags = sender_tags
      }).catch((error) => {
        if (error.response.data.errors) {
          this.$root.pushAppMessages(error.response.data.errors.map((message) => {
            return this.$t(message)
          }))
        }

        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.senders-filter {
  .control{
    @include font--small;

    font-weight: bold;
  }
  &-wrapper{
    padding-top: $padding18;

    &.off{
      overflow: hidden;
      height: 30px;
      margin-bottom: 22px;
      -webkit-mask-image: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0)), linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
      -webkit-mask-size: 100% 50%;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: left top, left bottom;
    }
  }
  &-tags {
    padding-bottom: $padding18;

    @media (max-device-width: $max-width--tablets) {
      display: flex;
      overflow-x: auto;
      touch-action: manipulation;
    }
  }

  .el-form {
    .el-form-item {
      width: 100%;
    }

    .el-input {
      width: 50%;

      @media (max-width: $max-width--tablets) {
        width: 100%;
      }
    }

    .reset {
      @include font--small;

      display: block;
      font-weight: bold;
      margin-bottom: $padding12;
    }
  }

}
</style>
